import React from 'react';
import Footer from '../Hoc/Footer/Footer';
import Header from '../Hoc/Header/Header';

const PolicyPage: React.FC = () => {
  return (
    <>
      <Header />
      <div className="wrap content-page">
        <h1 className="page-title">Политика конфиденциальности</h1>
        <div className="static-page__content">
          <div className="editor-content">
            <p>
              Политика конфиденциальности содержит пояснения о том, как
              используется информация, которую Вы предоставляете.
            </p>
            <p>
              Сайт splo.team уважает Ваше право и соблюдает
              конфиденциальность при заполнении, передаче и хранении Ваших
              конфиденциальных сведений. Размещение заявки на сайте splo.team
              означает Ваше согласие на обработку данных и дальнейшую передачу
              ваших контактных данных нашей компании ООО «Сплотим» (юридический
              адрес 193091, г. Санкт-Петербург, Октябрьская наб., д. 6, корп.3).
            </p>
            <p>
              Под данными подразумевается информация, относящаяся к субъекту
              персональных данных, в частности имя, контактные реквизиты (адрес
              электронной почты, контактный телефон) и иные данные, относимые
              Федеральным законом от 27 июля 2006 года № 152-ФЗ «О персональных
              данных» к категории персональных данных. В том случае, если Вы
              предоставляете нам персональные данные третьих лиц, Вы несете
              ответственность за информирование третьей стороны об использовании
              таких данных и за получение соответствующего согласия.
            </p>
            <p>
              Целью обработки персональных данных является оказание компанией
              ООО «Сплотим» услуг по предоставлению площадки для занятия
              пляжными видами спорта.
            </p>
            <p>
              Аналогично, при необходимости достижения вышеуказанных целей
              (занятия пляжными видами спорта), сторонние организации также
              могут получить доступ к Вашим персональным данным, например, те,
              кто оказывает услуги по проведению занятий пляжным
              волейболом/теннисом/футболом.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PolicyPage;
